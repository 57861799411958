.container{
    margin-top:80px;
    margin-bottom:50px;
}
.card{
    border-radius: 0;
    border-left: solid #C8E8BD 3px;
    border-top: solid #C8E8BD 3px;
    border-right: solid #E8BDC8 3px;
    border-bottom: solid #E8BDC8 3px;
    margin-top: 48px;
    height: 480px;
}
.formHeader{

    height:50px;
    color:rgb(0, 0, 0);
    display: flex;
    justify-Content: center;
    align-Items: center;
    margin-top:10px;

}
.logo{
    margin-top:20px;
    height:60px;
    width:60px;

}
.formHeadertext{
    margin-Top:13px;
    margin-Bottom:13px;
    font-Weight:700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    font-family: 'Roboto', sans-serif;
}
.formGroup{
    display: flex;
    flex-Direction:column;
}
.formLabel{
    align-Self: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 5px;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}
.formTextbox{
    width:100%;
    align-Self: center;
    border-radius: 0;
    font-family: 'Roboto', sans-serif;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}
.btnloginForm{
    height:40px;
    color:#FFFFFF;
    width:100%;
   
}
.btnLogin{
    background-Color: #666666;
    margin-top:16px;
    font-family: 'Roboto', sans-serif;
    font-Weight:700;
    border-radius: 0;
    font-Size: 1.4rem;
    line-height: 2rem;
}
.btnRegister{
    color:#86E3F8;
    font-family: 'Roboto', sans-serif;
    font-Weight:700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}
.textArea{
    font-Size: 1.6rem;
    line-height: 2.6rem;
}
.btnCancel{
    height:40px;
   
    font-family: 'Roboto', sans-serif;
    font-Weight:700;
    color:#666;
    width:100%;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}
