.icon{
    font-size: 16px;
  
    font-weight: bolder;
    cursor: pointer;
}


.iconSaved{
    font-size: 16px;
  
    font-weight: bolder;
    color: #f44336;
    cursor: pointer;
}

