.container {
    margin-Top: 112px;
    margin-bottom: 54px;
    height: 182px;
}

.cardProfileheader {
    border-radius: 5px;
    font-Size: 16px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    height: 182px;
}

.card {
    border-radius: 5px;
    font-Size: 16px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
}

.colLogo {
    margin: 0px;
    padding: 0px;
    height: 48px;
    width: 48px;
    padding: 16px;
}

.imgLogo {
    height: 80px;
    width: 80px;
}

.name {
    font-Size: 20px;
    padding: 16;
    margin-top: 40px;
    margin-left: 56px;
}

.cardFooter {
    height: 54px;
    margin: 0;
    padding: 0;
    background-color: white;
}

.navbarFooter {
    margin-top: 4px;
    margin-left: 24px;
}

.navlinkFooter {
    margin-right: 24px;
    font-Size: 1.8rem;
    line-height: 2.4rem;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
}

.card {
    width: 100%;
    height: 100%;
    border-radius: 5;
    border: 0px;
    box-Shadow: 1px 2px 5px 0px #d1d1d1;
    margin-top: 8px;
}

.imgLogo {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border-radius: 50%;
}

.cardText {
    font-Size: 16px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
}

.Colname {
    margin-top: 8px;
    margin-left: 8px;
    padding: 0;
}

.views {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 40px !important;
    margin: 0px;
}

.viewsText {
    font-size: 14px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 400;
    padding: 0;
    margin: 4px;
    width: 56px;
    color: #9C9D9D;
}

.iconTotal {
    color: #4036C9;
}

.iconPros {
    color: #36C940;
}

.iconCons {
    color: #C94036;
}

.colContent {
    padding: 0;
    margin: 0;
}

.cardCotent {
    font-Size: 16px;
    padding: 0;
    margin-Top: 5px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 400;
    margin-left: 56px;
    line-height: 16px;
    ;
}

.commentLabel {
    font-Size: 16px;
    margin: 16px 0px 16px 0px;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
}

.colComentTextarea {
    margin: 0px 0px 16px 0px;
    padding: 0;
}

.comentTextarea {
    height: 120px;
    border-radius: 5px;
}

.prosCol {
    background-Color: #36C940;
    height: 50px;
    color: #FFFFFF;
    display: flex;
    justify-Content: center;
    align-Items: center;
}

.consCol {
    background-Color: #C94036;
    height: 50px;
    color: #FFFFFF;
    display: flex;
    justify-Content: center;
    align-Items: center;
}

.prosText {
    font-Weight: 700;
    font-family: 'Roboto', sans-serif;
}

.consText {
    font-Weight: 700;
    font-family: 'Roboto', sans-serif;
}

.btnCancel {
    margin-Top: 8px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    width: 106px;
    font-size: 15px;
    height: 40px;
}

.btnComment {
    margin-Top: 8px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    background-color: #4036C9;
    width: 106px;
    font-size: 15px;
    height: 40px;
}

.colbtnComment {
    margin: 0px 8px 0px 0px;
    padding: 0;
    width: 106px;
}

.btnCreate {
    background-color: #4036C9;
    margin-Top: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    width: 100%;
    height: 48px;
    border-radius: 5;
    border: 0px;
    box-Shadow: 1px 2px 5px 0px #d1d1d1;
}