.nabvar {
    padding-Top: 12px;
    height: 70px;
    font-family: 'Roboto', sans-serif;
    background-color: #FFFFFF;
    font-weight: 700;
    box-Shadow: 1px 2px 5px 0px #d1d1d1;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}

.logo {
    height: 48px;
    width: 48px;
}

.searchBox {
    margin-left: 100px!important;
}

.searchBoxControl {
    width: 304px !important;
    border-radius: 15px !important;
    font-size: 16px !important;
    visibility: hidden !important;
}

.btnSearch {
    border-radius: 50% !important;
    font-size: 18px !important;
    background-color: #6D85CC !important;
    margin-left: 8px !important;
    visibility: hidden !important;
}

.searchNone {
    display: none !important;
    ;
}

@media only screen and (max-width:999px) {
    .toogle {
        background-color: white;
        margin-top: 16px;
        width: 100%;
        box-shadow: 1px 2px 5px 0px #888888;
    }
    .btnSearch {
        display: none !important;
    }
    .searchBoxControl {
        display: none !important;
    }
    .btnCreate {
        display: none !important;
    }
    .btnCreate2 {
        display: block !important;
    }
}

@media only screen and (min-width:1000px) {
    .btnCreate2 {
        display: none !important;
    }
}

.btnCreate {
    background-color: #6D85CC !important;
    height: 32px !important;
    width: 136px !important;
    font-size: 14px !important;
    margin-left: 16px !important;
    border-radius: 5px !important;
    font-Size: 1.4rem !important;
    line-height: 2.0rem !important;
}

.btnCreate2 {
    background-color: #6D85CC !important;
    height: 32px !important;
    width: 136px !important;
    font-size: 14px !important;
    margin-left: 16px !important;
    border-radius: 5px !important;
    font-Size: 1.4rem !important;
    line-height: 2.0rem !important;
}

.iconCreate {
    margin-right: 5px;
    font-Size: 1.4rem !important;
    line-height: 2.0rem !important;
    ;
}

.navlinkLogout {
    margin-left: 10px;
}