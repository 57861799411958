.colComentTextarea {
    margin: 0px 0px 8px 0px;
    padding: 0;
}
.commentimg{
    height: 40px;
    width: 40px;
}
.comentTextarea {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    border-radius: 10px;
  
    box-sizing: border-box;
    resize:none;
    height: 32px;
 
    overflow-y: hidden;
    word-wrap:break-word;
    padding: 8PX;
    margin: 0;
  
    background-color: #f0eeee;
}
.btnComment {

    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    background-color: #0084ff !important;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 1rem;
    height: 40px !important;
    
    color: white !important;
    border-radius: 20px;
}