@media only screen and (max-width:576px) {
    .name {
        font-Size: 20px;
        text-align: center;
    }
}

@media only screen and (width <=400px) {
    .name {
        font-Size: 16px;
        text-align: center;
        margin-left: 40px;
    }
}

@media only screen and (width <=300px) {
    .name {
        font-Size: 16px;
        text-align: center;
        margin-left: 80px;
    }
}

@media only screen and (min-width:576px) {
    .name {
        font-Size: 20px;
        padding: 16;
        margin-top: 40px;
        margin-left: 45px;
    }
}

.container {
    margin-Top: 112px;
    margin-bottom: 54px;
    height: 182px;
}

.cardProfileheader {
  
    font-Size: 16px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    height: 182px;
    width: 100%;
}

.cardPersonalinfo {
    border-radius: 20px;
    font-Size: 16px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    margin-top: 16px;
    margin-right: 8px;
    height: 100%;
    background-color: #f7f8fc;
   
   
}

.colLogo {
    position: relative;
    margin: 0px;
    padding: 0px;
    height: 48px;
    width: 48px;
    padding: 16px;
}

.iconCreate {
    height: 18px;
    width: 18px;
    color: white;
    position: relative;
    top: calc(50% - 10px);
}

.contairIcon {
    position: absolute;
    text-align: center;
    height: 32px;
    width: 32px;
    top: 70px;
    bottom: 0;
    left: 70px;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: #6D85CC !important;
    border-radius: 50%;
}

.contairIcon:hover .iconCreate {
    height: 24px;
    width: 24px;
    color: white;
    position: relative;
    top: calc(50% - 10px);
}

.contairIcon:hover {
    position: absolute;
    text-align: center;
    height: 40px;
    width: 40px;
    top: 70px;
    bottom: 0;
    left: 70px;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: blue !important;
    border-radius: 50%;
}

.iconEditname {
    color: black;
    margin-left: 5px;
}

.iconEditname:hover {
    color: black;
    margin-left: 5px;
    width: 24px;
    height: 24px;
    color: blue !important;
}

.imgLogo {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border-radius: 50%;
}

.cardFooter {
    height: 54px;
    margin: 0;
    padding: 0;
    background-color: white;
}

.navbarFooter {
    margin-top: 4px;
    margin-left: 24px;
}

.navlinkFooter {
    margin-right: 24px;
    font-Size: 1.8rem;
    line-height: 2.4rem;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
}

.Textarea {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    border: 0px;
    border-bottom: 1px solid gray;
    border-radius: 0px;
    text-align: justify;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    height: 24px;
    padding: 0;
    margin: 0;
}

.iconEditaddress {
    color: black;
    margin-left: 5px;
    position: absolute;
    top: -1px;
    left: 95%;
    width: 16px;
    height: 16px;
}

.iconEditaddress:hover {
    color: black;
    margin-left: 5px;
    position: absolute;
    top: -1px;
    left: 95%;
    width: 20px;
    height: 20px;
}

.colComentTextarea {
    position: relative;
}

.colTextarea {
    margin: 0px 0px 8px 0px;
    padding: 0;
}

.Textarea:focus {
    border-bottom: 2px solid rgb(35, 156, 226) !important;
    box-shadow: none !important;
    outline: none;
}

.rowcontent {
    margin-top:24px;
    margin-bottom: 32px;
}

.btnCancel {
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 16px;
}

.btnComment {
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    background-color: #6D85CC !important;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 8px;
    color: black !important;
}

.rowbtnComment {
    margin-left: 8px;
    text-align: right;
}

.rowbtnInvicomment {
    display: none;
}

.colbtnComment {
    margin-left: 6px;
    padding: 0;
    width: 106px;
}