.colCommentpros {

    margin-top: 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;

    
}

.colCommentcons {

    margin-top: 8px;
    
    border-radius: 0px 0px 10px 0;
    padding: 6px;
}

.rowComment {
    padding: 0px;
    margin: 0px;
}

.containerComment {

    background-color: white;
    padding: 16px;
    
}

.cardText {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    padding: 0;
    margin: 0;
    font-Weight: 600;
 
}

.commentText {
    font-Size: 1.4rem;
    line-height: 2rem;
    padding: 0;
    margin: 0;
   
    font-Weight: 500;
}

.cardTextTime {
    font-Size: 1.1rem;
    line-height: 1.6rem;
    padding: 0;
    margin: 0;

    font-Weight: 300;
}

.imgLogo {
    height: 40px;
    width: 40px;
    margin-top: 4px;
}

.colLogo {
    margin: 0px;
    padding: 0px;
    height: 48px;
    width: 48px;
}

.Colname {
    margin-top: 8px;
    margin-left: 8px;
    padding: 0;
}

.colContent {
    padding: 0;
    margin: 0px;
   
}

.cardCotent {
    font-Size: 1.4rem;
    line-height: 2rem;
    padding: 0;
    margin-Top: 4px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 400;
    margin-left:64px;
}

.colComentTextarea {
    margin: 0px 0px 16px 0px;
    padding: 0;
}

.comentTextarea {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    border: 0px;
    border-bottom: 2px solid gray;
    border-radius: 0px;
    text-align: justify;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    height: 24px;
    padding: 0;
    margin: 0;
}

.comentTextarea:focus {
    border-bottom: 2px solid rgb(35, 156, 226) !important;
    box-shadow: none !important;
    outline: none;
}

.btnCancel {
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 16px;
}

.btnComment {
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    background-color: #4036C9;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 8px;
}

.rowbtnComment {
    margin-left: 8px;
}

.rowbtnInvicomment {
    display: none;
}

.repliesText {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    font-Weight: 500;
    font-family: 'Roboto', sans-serif;
    color: #4036C9;
    width: 80px;
   
}

.colReplies {
    margin: 0;
    padding: 0;
}

.arrowDown {
    font-Size: 1.4rem;
    line-height: 1.8rem;
}

.btnReplies {
    cursor: pointer;
    width: 80px;
    height: 100%;
   
    margin-top: 8px;
}
.likebutton{
    font-size: 18px !important;;
    margin-left: 64px;
}
.likebutton2{
    font-size: 18px !important;
    margin: 0px 0 0px 56px;
}
.likedbutton{
    color: #0084ff;
font-size: 18px !important;
margin-left: 64px;
}
.likedbutton2{
    color: #0084ff;
    font-size: 18px !important;;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    margin: 0px 0 0px 56px;
    }
.likecount{
    font-size: 12px;
    margin-right: 16px !important;;
    margin: 0px 0 0px 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.likecount2{
    font-size: 12px;
    margin-right: 16px !important;;
    margin: 0px 0 0px 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}