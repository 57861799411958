.cardHomepage {
    border-radius: 20px;
    font-Size: 16px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    margin-top: 16px;
    margin-right: 8px;
    height: 100%;
    background-color: #f7f8fc;
   
   
}