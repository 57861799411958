body {
    background-color: #f7f7f9;
}

.container {
    margin-Top: 22px;
    margin-bottom: 50px;
}

.card {
    width: 100%;
    height: 100%;
    border-radius: 5;
    border: 0px;
    box-Shadow: 1px 2px 5px 0px #d1d1d1;
    margin-top: 8px;
}

.colLogo {
    margin: 0px;
    padding: 0px;
    height: 48px;
    width: 48px;
}

.imgLogo {
    height: 48px;
    width: 48px;
}

.textName {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
}

.cardTextTime {
    font-Size: 1.2rem;
    line-height: 1.6rem;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-Weight: 300;
}

.Colname {
    margin-top: 8px;
    margin-left: 8px;
    padding: 0;
}

.rowName {
    margin-top: 16px;
    margin-left: 16px;
}

.rowHeader {
    background-color: #F0ECEB !important;
    height: 32px;
}

.trash {
    font-size: 28px;
}

.views {
    margin-top: 16px;
    margin-bottom: 8px;
    margin-left: 24px;
}

.cardColumn {
    margin-bottom: 16px;
}

.viewsText {
    font-Size: 1.2rem;
    line-height: 1.6rem;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    padding: 0;
    margin: 4px;
    width: 56px;
    color: #9C9D9D;
}

.iconTotal {
    color: #6D85CC;
    width: 20px;
    height: 20px;
    padding: 0;
}

.iconPros {
    color: #36C940;
    width: 20px;
    height: 20px;
}

.iconCons {
    color: #C94036;
    width: 20px;
    height: 20px;
}

.colContent {
    padding: 0;
    margin: 0;
}

.rowContent {
    margin-left: 10px;
    margin-top: 16px;
}

.cardCotent {
    padding: 0;
    margin-Top: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 400;
    margin-left: 8px;
    font-Size: 1.4rem;
    line-height: 2rem;
    text-indent: 0px;
}

.commentLabel {
    font-Size: 15px;
    margin: 16px 0px 16px 0px;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
}

.colComentTextarea {
    margin: 0px 0px 8px 0px;
    padding: 0;
}

.comentTextarea {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    border: 0px;
    border-bottom: 2px solid gray;
    border-radius: 0px;
    text-align: justify;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    height: 24px;
    padding: 0;
    margin: 0;
}

.comentTextarea:focus {
    border-bottom: 2px solid rgb(35, 156, 226) !important;
    box-shadow: none !important;
    outline: none;
}

.btnCancel {
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 16px;
}

.btnComment {
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    background-color: #6D85CC !important;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 8px;
    color: black !important;
}

.rowbtnComment {
    margin-left: 8px;
    text-align: right;
}

.rowbtnInvicomment {
    display: none;
}

.prosCol {
    background-Color: #C8E8BD;
    height: 50px;
    color: black;
    display: flex;
    justify-Content: center;
    align-Items: center;
    border-radius: 15px 0 0 0px;
    margin-right: 1px;
}

.consCol {
    background-Color: #E8BDC8;
    height: 50px;
    color: black;
    display: flex;
    justify-Content: center;
    align-Items: center;
    border-radius: 0px 15px 0px 0px;
    margin-left: 1px;
}

.prosText {
    font-Weight: 700;
    font-family: 'Roboto', sans-serif;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}

.consText {
    font-Weight: 700;
    font-family: 'Roboto', sans-serif;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}

.btnCancel {
    margin-left: 4px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    width: 106px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
}

.btnComment {
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    background-color: #4036C9;
    width: 106px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
}

.colbtnComment {
    margin-left: 6px;
    padding: 0;
    width: 106px;
}

.btnCreate {
    background-color: #4036C9;
    margin-Top: 8px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    width: 100%;
    height: 48px;
    border-radius: 5;
    border: 0px;
    box-Shadow: 1px 2px 5px 0px #d1d1d1;
    font-Size: 1.4rem;
    line-height: 2rem;
}

.rowChoices {
    margin-top: 16px;
}

.colChoicespros {
    background-color: #C8E8BD;
    height: 42px;
    display: flex;
    justify-Content: center;
    align-Items: center;
    border-radius: 10px 0 0 10px;
    margin-right: 1px;
}

.colChoicescons {
    background-color: #E8BDC8;
    height: 42px;
    display: flex;
    justify-Content: center;
    align-Items: center;
    border-radius: 0 10px 10px 0;
    margin-left: 1px;
}

.textChoicespros:hover {
    background-color: #C8E8BD;
}

.textChoicescons:hover {
    background-color: #E8BDC8;
}

.textChoicespros {
    font-Size: 1.4rem;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.textChoicescons {
    font-Size: 1.4rem;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.textChoicespros2:disabled {
    font-Size: 1.4rem;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    background-color: #C8E8BD;
}

.textChoicescons2:disabled {
    font-Size: 1.4rem;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    background-color: #E8BDC8;
}

.iconChoicespros {
    font-Size: 2.0rem;
    line-height: 2.4rem;
}

.iconChoicescons {
    font-Size: 2.0rem;
    line-height: 2.4rem;
}