@media only screen and (max-width:576px) {
    .name {
        font-Size: 20px;
        text-align: center;
    }
}

@media only screen and (width <=400px) {
    .name {
        font-Size: 16px;
        text-align: center;
        margin-left: 40px;
    }
}

@media only screen and (width <=300px) {
    .name {
        font-Size: 16px;
        text-align: center;
        margin-left: 80px;
    }
}

@media only screen and (min-width:576px) {
    .name {
        font-Size: 20px;
        padding: 16;
        margin-top: 40px;
        margin-left: 45px;
    }
}

.container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
 
}
.cardbody{
    margin: 0px !important ;
    padding: 0px !important ;
}
.cardProfileheader {
  
    font-Size: 16px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-Weight: 500;
    height: 182px;
    width: 100%;
}

.hottopiccard {
    border-radius: 20px;
    font-Size: 16px;
    line-height: 16px;
    padding: 0px 0px 32px 0 !important;
    margin: 0px !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-Weight: 500;
    margin-top: 16px;
    margin-right: 8px;
    background-color: #f7f8fc;
    position: relative;
    padding: 0px 32px 0px 32px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 0px !important;
   
}
.hottopicrow{
    padding: 8px 32px 0px 32px;
}
.profileimage{
    position: relative;
 
}
.img{
    width: 80px;
    height: 80px;
    background-image: url('../images/users.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    border-radius: 40px;
}
.colLogo {
    position: relative;
    margin: 0px;
    padding: 0px;
    height: 48px;
    width: 48px;
    padding: 16px;
}

.iconCreate {
    height: 18px;
    width: 18px;
    color: white;
    position: relative;
    top: calc(50% - 10px);
}

.contairIcon {
    position: absolute;
    text-align: center;
    height: 32px;
    width: 32px;
    top: 70px;
    bottom: 0;
    left: 70px;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: #6D85CC !important;
    border-radius: 50%;
}

.contairIcon:hover .iconCreate {
    height: 24px;
    width: 24px;
    color: white;
    position: relative;
    top: calc(50% - 10px);
}

.contairIcon:hover {
    position: absolute;
    text-align: center;
    height: 40px;
    width: 40px;
    top: 70px;
    bottom: 0;
    left: 70px;
    right: 0;
    padding: 0;
    margin: 0;
    background-color: blue !important;
    border-radius: 50%;
}

.iconEditname {
    color: black;
    margin-left: 5px;
}

.iconEditname:hover {
    color: black;
    margin-left: 5px;
    width: 24px;
    height: 24px;
    color: blue !important;
}

.imgLogo {
    height: 32px;
    width: 32px;
    border-radius: 50%;
   

}

.hottopiccol{
    margin-top: 40px;
 
}
.hottopicrow:hover{
    background-color: #fff;
    cursor: pointer;
    .bordeline{
        border-bottom:solid 1px  #c7c7c7;
        width: 100%;
        margin: 0px;
        padding: 0px !important;
       
    }
}
.labeltitle{
    font-size: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.colcontent{
    margin-top:32px;
  
    text-align: justify;
    
}
.colreact{
    margin-top: 16px;
    margin-left: -8px;

}
.count{
    font-size: 10px;
    margin-right: 16px;
    
}
.cardFooter {
    height: 54px;
    margin: 0;
    padding: 0;
    background-color: white;
}

.navbarFooter {
    margin-top: 4px;
    margin-left: 24px;
}

.navlinkFooter {
    margin-right: 24px;
    font-Size: 1.8rem;
    line-height: 2.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-Weight: 700;
}

.Textarea {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-Weight: 500;
    border: 0px;
    border-bottom: 1px solid gray;
    border-radius: 0px;
    text-align: justify;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    height: 24px;
    padding: 0;
    margin: 0;
}

.iconEditaddress {
    color: black;
    margin-left: 5px;
    position: absolute;
    top: -1px;
    left: 95%;
    width: 16px;
    height: 16px;
}

.iconEditaddress:hover {
    color: black;
    margin-left: 5px;
    position: absolute;
    top: -1px;
    left: 95%;
    width: 20px;
    height: 20px;
}

.colComentTextarea {
    position: relative;
}

.colTextarea {
    margin: 0px 0px 8px 0px;
    padding: 0;
}

.Textarea:focus {
    border-bottom: 2px solid rgb(35, 156, 226) !important;
    box-shadow: none !important;
    outline: none;
}

.rowreact {
  padding: 0;
  margin: 0;
  width: 100%;
 

}
.colbordeline{
    text-align: center;
    
}
.bordeline{
   
    border-bottom:solid 1px  #c7c7c7;
    width: 90%;
    margin: 0px;
    padding: 0px;
    margin-left: 24px;
}
.rowbordeline{
    margin-top: 40px;
}
.profilebg{
    background-image: url('../images/profilebg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
    height: 200px;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 20px 20px 0px 0px;
}
.profilename{

   
    width: 100%;
    margin-left: 72px;
    position: relative;
 
   
}
.labelname{

    font-size: 16px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
}
.labeltime{

    font-Size: 1.2rem;
    line-height: 1.6rem;
    padding: 0;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
}
.labelcontent{
   
    font-size: 16px;
    line-height: 1.2;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.profileemail{
 
    width: 100%;
    margin-left: 72px;
    position: relative;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  

}
.labelemail{
    margin-top: 24px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #097dff;
}


.labeladdress{
    margin-top: 72px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color:#8e8c8c;
 
}
.profileaddress{
 
    width: 100%;
    margin-left: 72px;
    position: relative;

    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
}
.labelcontactwork{
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color:#8e8c8c;
 
}
.profilecontactwork{
 
    width: 100%;
    margin-left: 72px;
    position: relative;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
}
.rowcontact{
    margin-bottom: 24px;
}
.btnCancel {
    margin-bottom: 16px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 16px;
}

.btnComment {
    margin-bottom: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-Weight: 700;
    background-color: #6D85CC !important;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 8px;
    color: black !important;
}

.rowbtnComment {
    margin-left: 8px;
    text-align: right;
}

.rowbtnInvicomment {
    display: none;
}

.colbtnComment {
    margin-left: 6px;
    padding: 0;
    width: 106px;
}