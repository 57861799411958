/* Style for the side navbar */
.sidenavbar {
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0e5c50;
    padding-top: 20px;
    
  }
  
  /* Style for the links inside the navbar */
  .sidenavbar ul {
    list-style-type: none;
    margin-top: 30%;

   
  }
  
  .sidenavbar ul li {
    padding: 8px 40px 8px 16px;
    margin-top: 16px;
    width: 200px;
  }
  
  .sidenavbar ul li a {
    color: white;
    text-decoration: none;
    display: block;
    font-size: 16px;
    font-weight: bolder;
  
  }
  
  .sidenavbar ul li:hover {
    background-color: #42887d;
    border-radius: 10px;
  }
  .icon{
    font-size: 24px;
    padding: 0;
    font-weight: bolder;
  }
  p{
    margin: 0 !important;

  }
  .logo{
   align-self: center;
   margin-top: 80px;
   
  }