body{
    background-color: #fff;
}
.container {
    margin-top: 10%;
}

.card {
    margin-top: 48px;
    height: 480px;
}

.label1 {
    font-size: 36px;
    justify-Content: center;
    font-weight: 700;
    width: 70%;
}

.divlogo {
    margin-top: 58px;
}

.divhoozh {
    margin-top: 58px;
}

.divlabel1 {
    margin-top: 32px;
}

.divready {
    margin-top: 56px;
    width: 266px;
}

.labelalready {
    padding-left: 88px;
    width: 480px;
}

.divlogin {
    margin-top: -16px;
    padding-left: 88px;
    width: 480px;
}

.divcreateaccount {
    padding-left: 88px;
    width: 480px;
}

.divgoogle {
    padding-left: 88px;
    width: 480px;
    margin-top: 8px;
}

.divarat {
    padding-left: 88px;
    width: 480px;
    margin-top: 64px;
}

.labelalready {
    font-size: 18px;
}

.labelarat {
    font-size: 48px;
    font-weight: 700;
}

.divcreateaccount {
    margin-top: 16px;
}

.label2 {
    font-size: 18px;
    justify-Content: center;
    font-weight: 300;
    width: 90%;
}

.divlabel2 {
    margin-top: 16px;
}

.lblhoozh {
    font-size: 64px;
    font-weight: 700;
}

.logo {
    height: 80px;
}

.formHeadertext {
    margin-Top: 16px;
    margin-Bottom: 13px;
    font-Weight: 700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    font-family: 'Roboto', sans-serif;
}

.formGroup {
    display: flex;
    flex-Direction: column;
}

.formLabel {
    align-Self: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 8px;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}

.formTextbox {
    width: 90%;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    align-Self: center;
    border-radius: 0;
    margin-top: 8px;
}

.btnloginForm {
    height: 40px;
    color: #FFFFFF;
    width: 200px;
}

.btnLogin {
    background-Color: #fff;
    margin-top: 32px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    border-radius: 50px;
    width: 90%;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    color: #0e5c50;
    border-color: #0e5c50;
}

.btngoogle {
    background-Color: #fff;
    margin-top: 32px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    border-radius: 50px;
    width: 90% !important;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    color: #0e5c50;
    border-color: #0e5c50;
}

.btnLogin:hover {
    background-color: #1c6c5c;
}

.btnRegister {
    background-color: #0e5c50;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    border-radius: 50px;
    width: 90%;
    margin: 0px;
}

.btnRegister:hover {
    background-color: #1c6c5c;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    border-radius: 50px;
    width: 90%;
}

.forgotpassword {
    color: #6D85CC;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    margin-top: 8px;
}