.views {
    margin-top: 16px;
    margin-bottom: 8px;
    margin-left: 24px;
}

.viewsText {
    font-Size: 1.2rem;
    line-height: 1.6rem;
    font-family: 'Segoe UI', sans-serif;
    font-Weight: 700;
    padding: 0;
    margin: 4px;
    width: 56px;
    color: #9C9D9D;
}

.iconTotal {
    color: #6D85CC;
    width: 20px;
    height: 20px;
    padding: 0;
}

.iconPros {
    color: #36C940;
    width: 20px;
    height: 20px;
}

.iconCons {
    color: #C94036;
    width: 20px;
    height: 20px;
}
.rowreact {
    padding: 0;
    margin: 0;
    width: 100%;
   
  
  }
  .colreact1{
    margin-top: 16px;
    margin-left: -8px;

}
.colreact2{
    margin-top: 24px;
    margin-left: 8px;

}
.count{
    font-size: 12px;
    margin-right: 32px;
    font-weight: 500;
    margin-left: 4px;
    margin-left: 4px;
}
.icon{
    font-size: 16px;
  
    font-weight: bolder;
    
}
.iconSaved{
    font-size: 16px;
  
    font-weight: bolder;
}
.iconSaved{
    font-size: 16px;
  
    font-weight: bolder;
    color: #f44336;
}