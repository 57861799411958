.container{
    margin-top:80px;
    margin-bottom:50px;
}
.card{
    border-radius: 0;
    border-left: solid #C8E8BD 3px;
    border-top: solid #C8E8BD 3px;
    border-right: solid #E8BDC8 3px;
    border-bottom: solid #E8BDC8 3px;
    margin-top: 48px;
    height: 480px;
}
.formHeader{

    height:50px;
    color:rgb(0, 0, 0);
    display: flex;
    justify-Content: center;
    align-Items: center;
    margin-top:10px;

}
.logo{
    margin-top:20px;
    height:60px;
    width:60px;

}
.formHeadertext{
    margin-Top:13px;
    margin-Bottom:13px;
    font-Weight:700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    font-family: 'Roboto', sans-serif;
}
.formGroup{
    display: flex;
    flex-Direction:column;
}
.formLabel{
    align-Self: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 5px;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}
.formTextbox{
    width:100% !important;
    align-Self: center !important;
    border-radius: 0  !important;
    font-family: 'Roboto', sans-serif !important;
    font-Size: 1.6rem !important;
    line-height: 2.6rem !important;
}
.btnloginForm{
    height:40px !important;
    color:black !important;
    width:100% !important;
   
}
.btnLogin{
    background-Color: #C8E8BD !important;;
    margin-top:16px  !important;;
    font-family: 'Roboto', sans-serif !important;;
    font-Weight:700 !important;;
    border-radius: 50px !important;;
    font-Size: 1.6rem !important;
    line-height: 2.6rem !important;
    border:0px solid bisque !important;
}
.btnRegister{
    color:#86E3F8;
    font-family: 'Roboto', sans-serif;
    font-Weight:700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}
.textArea{
    font-Size: 1.6rem;
    line-height: 2.6rem;
}
.btnCancel{
    height:40px !important;
    background-Color: #E8BDC8 !important;;
    font-family: 'Roboto', sans-serif !important;
    font-Weight:700  !important;
    color:#666 !important;
    width:100%  !important;
    font-Size: 1.6rem !important;
    line-height: 2.6rem !important;
    border-radius: 50px !important;;
}
.colComentTextarea{
    margin:0px 0px 8px 0px;
    padding: 0;
}
.textArea{
    font-Size: 1.4rem !important;
    line-height: 1.8rem !important;
    font-family: 'Roboto', sans-serif !important;
    font-Weight:500  !important;
    border:0px !important;
    border-bottom:2px solid gray !important;
    border-radius: 0px !important;
    text-align: justify !important;
    box-sizing: border-box !important;
    resize: none  !important;
    overflow: hidden  !important;
    height:24px !important;
    padding: 0  !important;
    margin-bottom:40px  !important;
    margin-top:40px !important;
}
.textArea:focus{
   
    border-bottom:2px solid rgb(35, 156, 226) !important;

    box-shadow: none !important;
    outline: none;
}