.container {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}
.lblvalidation{
   
    width: 60%;
    font-Size: 1.2rem;
    line-height: 2.6rem;
    align-Self: center;
    color:red;
}
.card {
    border-radius: 10px;
    margin-top: 40%;
    height: 80%;
}

.formHeader {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-top: 8px;
    margin-left: 8px;
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.formHeader:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}

.logo {
    margin-top: 24px;
    height: 112px;
    width: 112px;
}

.formHeadertext {
    font-Weight: 700;
    font-Size: 2.6rem;
    line-height: 2.6rem;
    font-family: 'Roboto', sans-serif;
}

.formHeadertext:hover {
    cursor: pointer;
    font-Weight: bolder;
}

.formGroup {
    display: flex;
    flex-Direction: column;
}

.formLabel {
    align-Self: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-top: 8px;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}

.formTextbox {
    width: 60%;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    align-Self: center;
    border-radius: 0;
    margin-top: 8px;
    height: 40px;
}

.txtemail {
    margin-bottom: 4px;
}

.txtsignin {
    margin-top: 16px;
    align-Self: left;
    font-size: 2.6rem;
    margin-left: 64px;
    font-weight: 600;
}

.btnloginForm {
    height: 40px;
    color: #FFFFFF;
    width: 200px;
}

.btnLogin {
    margin-top: 32px;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    border-radius: 50px;
    width: 90%;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    color: #FFFFFF;
    width: 300px;
}

.btnRegister {
    color: #6D85CC;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
}

.forgotpassword {
    color: #6D85CC;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    font-Size: 1.6rem;
    line-height: 2.6rem;
    margin-top: 8px;
}