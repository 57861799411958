.row {
    margin-Top: 20px;
    padding: 0;
}

.cardTitle {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    padding: 0;
    margin: 0;
    font-Weight: 500;
    font-family: 'Roboto', sans-serif;
}

.colLikebtn
{
    margin: 0px 0px 16px 40px;
}
.repliesText {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    font-Weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.imgLogo {
    height: 32px;
    width: 32px;
}

.colLogo {
    margin: 0px 0px 0px 32px;
    padding: 0px;
    height: 32px;
    width: 32px;
}

.rowName {
    margin: 0px 0px 0px 8px;
    padding: 0px;
}

.replyText {
    font-Size: 1.4rem;
    line-height: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.colreplyText {
    padding: 0;
    margin: 16px 0px 0px 24px;
}

.colComentTextarea {
    margin: 8px 0px 16px 72px;
    padding: 0;
}



.rowbtnInvi {
    display: none;
}

.cardText {
    line-height: 1.8rem;
    padding: 0;
    margin: 0;
    font-Weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3rem;
}

.cardTextTime {
    line-height: 1.6rem;
    padding: 0;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-Weight: 300;
    font-size: 1.1rem;
}

.colContent {
    padding: 0;
    margin: 0;
}
.rowComment {
    padding: 0px;
    margin: 0px;
}
.cardCotent {
    line-height: 2rem;
    margin-top: 4px;
    font-size: 1.4rem;
    display: inline-block;
    word-wrap: break-word;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-Weight: 400;
    
}

.btnCancel {
    margin-bottom: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-Weight: 700;
    width: 90px;
    font-Size: 1.4rem;
    line-height: 2rem;
    height: 40px;
    margin-left: 0px;
}


.btnComment {

    font-family: 'Roboto', sans-serif;
    font-Weight: 700;
    background-color: #0084ff !important;
    width: 98px;
    font-Size: 1.4rem;
    line-height: 1rem;
    height: 40px !important;
    
    color: white !important;
    border-radius: 20px;
}

.rowbtnComment {
    margin-left: 16px;
}

.comentTextarea {
    font-Size: 1.4rem;
    line-height: 1.8rem;
    font-family: 'Roboto', sans-serif;
    font-Weight: 500;
    border-radius: 10px;
  
    box-sizing: border-box;
    resize:none;
    height: 32px;
   
    overflow-y: hidden;
    word-wrap:break-word;
    padding: 8PX;
    margin: 0;

    background-color: #f0eeee;
}

.comentTextarea:focus {
    border-bottom: 2px solid rgb(35, 156, 226) !important;
    box-shadow: none !important;
    outline: none;
    word-wrap:break-word;
  
}